import React, { useEffect, useState } from 'react';
import './Hero.css'
import heroimage from './hero-image.png'
function Hero() {
    const [text, setText] = useState('');
    const originalText = "Innovate. Transform. Excel.\nUnleash. Possibilities.";
    const [isTextLoaded, setIsTextLoaded] = useState(false);

    useEffect(() => {
        let charIndex = 0;

        const typingInterval = setInterval(() => {
            setText(originalText.slice(0, charIndex));
            charIndex++;

            if (charIndex > originalText.length) {
                clearInterval(typingInterval);
            }
        }, 100); 

        setTimeout(() => {
            setIsTextLoaded(true);
        }, 5000);
        return () => clearInterval(typingInterval);

       
    }, []);

    return (
        <>
            <section id="Hero" className="d-flex align-items-center">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h1 className="text-2xl font-bold" style={{ whiteSpace: 'pre-line', fontSize: '3rem' }}>{text}</h1>
                            {isTextLoaded && (
                            <button
                                className="bg-gold text-black w-50 fw-bold px-4 py-2 rounded-md mt-4"
                                onClick={() => {
                                    // Handle button click
                                }}
                            >
                                Get Started
                            </button>
                        )}
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 d-flex justify-content-center align-items-center">
                            <img src={heroimage} alt='heroimage' className="w-65 h-65 animate-pulse" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;