import React from 'react';
import './Services.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faGlobe, faMobileAndroid, faSchool, faUsersGear, faCalculator, faFileInvoice, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
function Services() {
    const icons = [faRobot, faGlobe, faMobileAndroid, faSchool, faUsersGear, faCalculator, faFileInvoice, faArrowTrendUp];

    const servicesData = [
        {
            icon: icons[0],
            heading: 'Al and Robotic Labs',
            description: `Embark on a transformative educational journey with our cutting-edge Robotics and Artificial Intelligence 
                    programs. Redefining student engagement with technology, we offer hands-on learning, 
                    fostering creativity through challenging projects. Our curriculum goes beyond theory, 
                    emphasizing programming proficiency for real-world application, preparing graduates for success in the 
                    dynamic realms of robotics and AI innovation.`,
        },
        {
            icon: icons[1],
            heading: 'Web Designing & Development',
            description: `Cypher Info Solutions excels in dynamic web development, specializing in tailored solutions. 
                    Our front-end developers craft captivating user interfaces with HTML, CSS, and JavaScript. Back-end experts ensure smooth 
                    operations using languages like Python. With full-stack proficiency, we offer comprehensive web services. 
                    Utilizing industry-standard tools, including Git and CMS like WordPress, we guarantee efficient, 
                    collaborative, and customized web solutions.`,
        },
        {
            icon: icons[2],
            heading: 'Android / IOS Mobile App Development',
            description: `Embark on a comprehensive mobile app development journey with us. We meticulously refine your concept, 
                    create intuitive designs, and employ precise coding practices. Rigorous testing ensures a bug-free, 
                    seamless experience across devices. From launch support to ongoing maintenance, our commitment extends, 
                    ensuring your app's optimal performance and continuous improvement.`,
        },
        {
            icon: icons[3],
            heading: 'School Management Software (ERP)',
            description: `Welcome to Cypher Info School ERP, revolutionizing educational institutions into efficient learning hubs. 
                    Our comprehensive software streamlines administrative and academic tasks, fostering unified communication, 
                    precise data management, and key features like attendance tracking and financial management. With a 
                    user-friendly interface, scalability, and customization options, join the educational revolution with 
                    Cypher Info School ERP for an elevated learning experience.`,
        },
        {
            icon: icons[4],
            heading: 'Software Development',
            description: `Software development is the systematic process of designing, coding, testing, and 
                            maintaining software applications. It involves translating user needs into functional solutions, 
                            emphasizing creativity, precision, and problem-solving. Developers use programming languages to 
                            create software that meets specific requirements, driving innovation in the digital landscape.`,
        },
        {
            icon: icons[5],
            heading: 'Accounting Software',
            description: `Accounting development streamlines financial processes, automating tasks like ledger management, 
                            payroll, and tax calculations. Utilizing software solutions, it enhances accuracy, reduces manual 
                            errors, and ensures compliance. With real-time insights, businesses can make informed financial 
                            decisions, fostering efficiency and transparency in their monetary operations.`,
        },
        {
            icon: icons[6],
            heading: 'GST Billing',
            description: `GST Billing simplifies the invoicing process by incorporating Goods and Services Tax (GST) compliance. 
                        It automates billing, calculates accurate GST amounts, and generates compliant invoices. 
                        This streamlines financial transactions, ensures adherence to tax regulations, and facilitates 
                        seamless business operations while promoting transparency and compliance.`,
        },
        {
            icon: icons[7],
            heading: 'Stock Management',
            description: `Stock Management optimizes inventory control by tracking stock levels, orders, and sales. 
                            It enhances efficiency by preventing overstock or stockouts, minimizing holding costs, and 
                            improving order fulfillment. With real-time insights, businesses can make informed decisions, 
                            reduce losses, and ensure a smooth supply chain for sustained operational success.`,
        },
    ];
    return (
        <>
            <section id='service' className='text-center'>
                <h2 style={{fontSize:'4rem', fontWeight:'500'}} className='mb-4'>Our Services</h2>
                <div className='container'>
                    <div className='row'>
                        {servicesData.map((service, index) => (
                            <div key={index} className='col-lg-3 mb-4'>
                                <div className='card shadow custom' style={{ height: '100%' }}>
                                    <div className='card-body d-flex flex-column'>
                                        <FontAwesomeIcon icon={service.icon} size='3x' className='mb-3 golden-icon' />
                                        <h5 className='card-title'>{service.heading}</h5>
                                        <p className='card-text flex-grow-1'>{service.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Services;