import './footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
function Footer() {
    return (
        <>
            <section id="contact" className="py-8">
                <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-0">
                    {/* First Column */}
                    <div className="bg-black p-4">
                        <h2 className="text-2xl font-bold mb-4 text-white">Connect With Us</h2>
                        <form>
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                                    Name:
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                                    Email:
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="message" className="block text-gray-700 font-bold mb-2">
                                    Message:
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    rows="4"
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Type your message here"
                                    required
                                ></textarea>
                            </div>

                            <button
                                type="submit"
                                className="block mx-auto bg-gold text-black fw-semibold px-4 py-2 rounded-md"
                            >
                                Submit
                            </button>
                        </form>
                    </div>


                    {/* Second Column */}
                    <div className="bg-black p-4">
                        <h2 className="text-2xl font-bold mb-4 text-white">Useful Links</h2>

                        <ul className="list-none pl-4">
                            <li className="flex items-center mb-2">
                                <span className="mr-2 text-gold">&#9658;</span>
                                <a href="#Hero" className="text-white fw-semibold hover:text-gold no-underline">Home</a>
                            </li>
                            <li className="flex items-center mb-2">
                                <span className="mr-2 text-gold">&#9658;</span>
                                <a href="#service" className="text-white fw-semibold hover:text-gold no-underline">Services</a>
                            </li>
                            <li className="flex items-center">
                                <span className="mr-2 text-gold">&#9658;</span>
                                <a href="#about" className="text-white fw-semibold hover:text-gold no-underline">About</a>
                            </li>
                        </ul>

                        <h2 style={{ paddingTop: '8px' }} className="text-3xl font-semibold mb-4 text-white">Social Networks</h2>

                        <div className="flex space-x-4 text-4xl cursor-pointer">
                            <FontAwesomeIcon icon={faWhatsapp} className="text-green-600" />
                            <FontAwesomeIcon icon={faFacebook} className="text-blue-700" />
                            <FontAwesomeIcon icon={faInstagram} className="text-pink-500" />
                            <FontAwesomeIcon icon={faLinkedin} className="text-blue-800" />
                        </div>
                    </div>


                    {/* Third Column */}
                    <div className="bg-black p-4">
                        <h2 className="text-white font-bold mb-4">Contact Us</h2>
                        <p className="text-white mb-2 font-bold text-3xl">Cypher Info Solutions</p>
                        <p className="text-white mb-2 text-justify">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-gold" />
                            {' '}Kizhakkekkonil Building <br /> Puttady, Iduki <br /> Kerala 685551
                        </p>
                        <p className="text-white">
                            <FontAwesomeIcon icon={faPhone} className="text-gold" />
                            {' '}9012 9228 88, 7012 8471 05
                        </p>
                        <p className="text-white">
                            <FontAwesomeIcon icon={faEnvelope} className="text-gold" />
                            {' '}cypherinfosolution@gmail.com
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;