import './about.css'
function About() {
    return (
        <>
            <section id="about" className="py-16 bg-gray-100">
                <div className="container mx-auto">
                    <h2 style={{ fontSize: '4rem', fontWeight: '500', color: 'white' }} className="text-4xl font-bold mb-8 text-center">About Us</h2>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-1">
                        {/* Left Column */}
                        <div className="left-column text-center p-3 md:text-left">
                            <h2 className="text-3xl font-extrabold mb-6">Why Us?</h2>
                            <ul className="text-left pl-6 fw-bold">
                                <li className="mb-3">
                                    <span className="text-gold ">&#10003; Cutting-edge Technology:</span>
                                    <span className='text-white'>Embrace the future with our AI and Robotic Labs.</span>
                                </li>
                                <li className="mb-3">
                                    <span className="text-gold">&#10003; Efficient Financial Operations:</span>
                                    <span className='text-white'>Simplify with GST Billing and Accounting Software.</span>
                                </li>
                                <li className="mb-3">
                                    <span className="text-gold">&#10003; Educational Excellence:</span>
                                    <span className='text-white'>Transform learning institutions with School Management Software.</span>
                                </li>
                                <li className="mb-3">
                                    <span className="text-gold">&#10003; Digital Presence:</span>
                                    <span className='text-white'>Captivate audiences with compelling Web Design and Development.</span>
                                </li>
                                <li>
                                    <span className="text-gold">&#10003; Versatile Solutions:</span>
                                    <span className='text-white'>From Software Development to Mobile App expertise, we cover it all.</span>
                                </li>
                                
                            </ul>
                            <p className='mb-4 py-3 text-white font-bold text-justify'>
                                Join us at Cypher Info Solutions, where innovation meets execution, and your digital aspirations become
                                reality. Explore the possibilities, transcend boundaries, and thrive in the digital landscape with 
                                our comprehensive suite of services.
                            </p>
                        </div>


                        {/* Right Column */}
                        <div className="right-column text-gray-800 p-4 md:p-8 bg-white shadow-md">
                            <p className="mb-4 text-justify font-semibold">
                                At Cypher Info Solutions, we pioneer innovation in diverse fields, specializing in AI and Robotic Labs,
                                GST Billing, Stock Management, Accounting Software, School Management Software (ERP), Web Designing
                                & Development, Software Development, and Android/iOS Mobile App Development. Our commitment is to
                                harness cutting-edge technologies, crafting tailored solutions to propel your business into the
                                future. With a focus on excellence, innovation, and client success, we redefine possibilities
                                in the digital landscape. Join us on a journey of transformation and technological advancement
                                with Cypher Info Solutions.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">Our Expertise</h2>
                            <p className="mb-4 text-justify font-semibold">
                                Cypher Info Solutions stands at the forefront of technological advancement, offering a spectrum
                                of services to meet the dynamic needs of the digital era. Our AI and Robotic Labs lead the way in
                                transformative educational programs, while our proficiency in GST Billing ensures streamlined
                                financial operations. With a keen focus on Stock Management and Accounting Software, we empower
                                businesses with efficient solutions.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">Innovative Solutions</h2>
                            <p className='mb-4 text-justify font-semibold'>
                                As a leading name in School Management Software (ERP), we revolutionize educational institutions,
                                enhancing administrative and academic functions. Our Web Designing & Development services create
                                captivating digital experiences, complemented by comprehensive Software Development solutions.
                                The realm of Android/iOS Mobile App Development is where we excel, providing seamless, user-centric
                                applications.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default About;