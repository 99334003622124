import React from 'react'
import logoImage from './logo.png'
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
function Header() {
    const navigation = [
        { name: 'HOME', href: '#Hero', current: true },
        { name: 'ABOUT', href: '#about', current: false },
        { name: 'SERVICES', href: '#service', current: false },
        { name: 'CONTACT US', href: '#contact', current: false },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <>
            <Disclosure as="nav" className="bg-black fixed top-0 w-full z-10">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                            <div style={{ height: '4rem' }} className="relative flex  items-center justify-between">
                                <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                                    {/* Mobile menu button*/}
                                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2">

                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="flex flex-1 items-center justify-between md:justify-start">
                                    <div className="flex flex-shrink-0 items-center">
                                        <a className="ms-5" href="#">
                                            <img src={logoImage} alt="Logo" width="50"
                                                height="50"
                                                className="" />
                                        </a>
                                    </div>
                                    <div className="hidden md:ml-6 md:block items-center justify-end flex-1">
                                        <div className="flex space-x-4 justify-content-end">
                                            {navigation.map((item) => (
                                                <a
                                                    style={{ color: "#ffff", fontWeight: "700" }}
                                                    key={item.name}
                                                    href={item.href}
                                                    className={classNames(
                                                        item.current ? 'bg-white-900 rounded-md text-decoration-none py-2 font-medium ' : 'rounded-md text-decoration-none py-2 font-medium'
                                                    )}
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </a>
                                            ))}
                                            <button
                                                className="bg-gold text-black fw-bold px-4 py-2 rounded-md"
                                                onClick={() => {
                                                    // Handle button click
                                                }}
                                            >
                                                Get Started
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="md:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {navigation.map((item) => (
                                    <Disclosure.Button
                                        style={{ color: "#ffff", fontWeight: "700" }}
                                        key={item.name}
                                        as="a"
                                        href={item.href}
                                        className={classNames(
                                            item.current ? 'bg-white-900 rounded-md text-decoration-none py-2 font-medium ' : 'block rounded-md text-decoration-none py-2 font-medium'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                                <button
                                    className="bg-gold text-black fw-bold px-4 py-2 rounded-md"
                                    onClick={() => {
                                        // Handle button click
                                    }}
                                >
                                    Get Started
                                </button>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

        </>
    )
}

export default Header