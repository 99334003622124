import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const heroSection = document.getElementById('Hero');
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            // Check if the scrollTop is greater than the height of the Hero section
            setIsVisible(scrollTop > heroSection.clientHeight);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <button
            className={`fixed bottom-4 right-4 bg-gold text-black p-4 transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'
                }`}
            onClick={scrollToTop}
        >
            <FontAwesomeIcon icon={faArrowUp}  className="text-1xl" />
        </button>
    );
}

export default ScrollToTopButton;
